import React, { ChangeEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TransformDate from "../../utils/TransformDate";
import { PostToGallery, Spinner } from "../index";
import {
  PrimaryColor,
  TableBodyGrey,
  TableHeaderGrey,
} from "../../shared/styles/colors";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import GalleryModel from "../../model/gallery";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinkIcon from "@mui/icons-material/Link";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import AlertService from "../../service/AlertService";
import ToastMessages from "../../model/enum/ToastMessages";
import { ToastContainer } from "react-toastify";
import GalleryDetailModal from "./GalleryDetailModal";
import CachedIcon from "@mui/icons-material/Cached";
import useStyles from "../styles/cardStyles";
import ApiService from "../../service/ApiService";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Gallery = () => {
  const classes = useStyles();
  const [gallery, setGallery] = useState<GalleryModel[]>([]);
  const [items, setItems] = useState<GalleryModel[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  //Get all the gallery from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<GalleryModel[]>(`${DatabaseEntities.GALLERY}`).then(
        (data: GalleryModel[]) => {
          setGallery(data);

          for (let i = 0; i < rowsPerPage && i < data.length; i++) {
            items.push(data[i]);
          }

          setToggleSpinner(false);
        }
      );
    };

    if (gallery.length <= 0) fetchData();
  }, [gallery]);

  const handleCreate = async (payload: any, notify: boolean = true) => {
    await ApiService.post<GalleryModel>(DatabaseEntities.GALLERY, payload)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.CREATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.CREATED_FAILED);
      });
  };

  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<GalleryModel>(
      `${DatabaseEntities.GALLERY}/${payload.id}`,
      payload
    )
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  const handleDelete = async (payload: any, notify: boolean = true) => {
    await ApiService.delete<any>(`${DatabaseEntities.GALLERY}/${payload.id}`)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.DELETED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.DELETE_FAILED);
      });
  };

  const resetList = (): void => {
    setGallery([]);
    setItems([]);
    setPage(0);
  };

  const handlePageChange = (
    _event: ChangeEvent<unknown> | null,
    pg: number,
    itemsPerPage: number = rowsPerPage
  ) => {
    setToggleSpinner(true);
    setPage(pg);
    let MaxElem = pg * itemsPerPage;

    items.forEach((_itm: GalleryModel, index) => {
      items.splice(index);
    });

    for (let i = MaxElem; i < MaxElem + itemsPerPage; i++) {
      if (i < gallery.length) items.push(gallery[i]);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setToggleSpinner(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let pg = parseInt(event.target.value);
    setPage((_page) => 0);
    handlePageChange(null, 0, pg);
  };

  return (
    <>
      <Card raised className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>Gallery</Typography>
          <div className={classes.headerDiv}>
            <PostToGallery handleCreate={handleCreate} />
            <Button onClick={resetList}>
              <CachedIcon />
            </Button>
          </div>
        </div>
        <CardContent>
          <Paper>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="customized table"
                padding="normal"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell align="right">Service</StyledTableCell>
                    <StyledTableCell align="right">Description</StyledTableCell>
                    <StyledTableCell align="right">Link</StyledTableCell>
                    <StyledTableCell align="right">CreatedAt</StyledTableCell>
                    <StyledTableCell align="center">
                      <MoreVertIcon />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {toggleSpinner ? (
                  <Spinner />
                ) : (
                  <TableBody>
                    {items.length > 0 ? (
                      items.map((row: GalleryModel) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell align="right">
                            {row.id}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.Service?.name}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.description}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Button onClick={() => window.open(row.imageUrl)}>
                              <LinkIcon />
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {TransformDate.toFullDate(row.createdAt)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <GalleryDetailModal
                              item={row}
                              handleUpdate={handleUpdate}
                              handleDelete={handleDelete}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableCell align="right">No items</StyledTableCell>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            component="div"
            count={gallery.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default Gallery;
