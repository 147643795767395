import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { Spinner } from "../../../components";
import ApiService from "../../../service/ApiService";
import AlertService from "../../../service/AlertService";
import DatabaseEntities from "../../../model/enum/DatabaseEntities";
import FirebaseService from "../../../service/FirebaseService";
import DeleteModal from "../../modal/DeleteModal";
import BundleModel from "../../../model/bundle";
import ProductModel from "../../../model/product";
import ToastMessages from "../../../model/enum/ToastMessages";
import EditIcon from "../../../assets/admin/edit-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "../../../assets/admin/upload-icon.svg";
import "./tableStyles.css";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

type Item = {
  id: number;
  productId: number | "";
  quantity: number;
};

const BundleModal = ({
  bundle,
  resetList,
  handleUpdate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = bundle != null;

  const [name, setName] = useState(edit ? bundle.name : null);
  const [description, setDescription] = useState(
    edit ? bundle.description : null
  );
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [items, setItems] = useState<Item[]>(
    edit ? bundle.BundleItems : [{ id: 1, productId: "", quantity: 0 }]
  );
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileName, setFileName] = useState("");
  const imageUrl = edit ? bundle.imageUrl : null;
  const [toggleSpinner, setToggleSpinner] = useState(true);

  // //Get all the products from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<ProductModel[]>(DatabaseEntities.PRODUCTS).then(
        (data) => {
          if (data.length == 0) setToggleSpinner(false);
          setProducts(data);
          setToggleSpinner(false);
        }
      );
    };
    if (toggleSpinner && products.length <= 0) {
      fetchData();
    }
  }, [products]);

  const handleName = (e: any) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const handleDescription = (e: any) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleAddItem = () => {
    const newItem: Item = {
      id: items.length + 1,
      productId: "", // Adjusting type for productService
      quantity: 0,
    };
    setItems([...items, newItem]);
  };

  const handleProduct = (e: any, index: number) => {
    const updatedItems = [...items];
    updatedItems[index].productId = Number(e.target.value);
    setItems(updatedItems);
  };

  const handleQuantity = (e: any, index: number) => {
    const updatedItems = [...items];
    updatedItems[index].quantity = parseInt(e.target.value) || 0;
    setItems(updatedItems);
  };

  const handleDeleteItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const submit = async () => {
    if (invalidInput()) return;

    const payload: BundleModel = {
      id: edit ? bundle.id : 0,
      name: name,
      description: description,
      items: items,
      isUltra: false,
      imageUrl: imageUrl,
    };

    if (edit) {
      await handleUpdate(payload);
    } else {
      if (file) {
        await FirebaseService.uploadFile(file, fileName, "Bundle")
          .then(async (url: string) => {
            payload.imageUrl = url;
            await handleCreate(payload);
          })
          .catch(() => {
            AlertService.error(ToastMessages.FAILED_UPLOAD);
          });
      } else {
        await handleCreate(payload);
      }
    }

    setName(null);
    setDescription(null);
    setFile(undefined);
    setItems([]);
    handleClose();
  };

  const handleCreate = async (payload: any, notify: boolean = true) => {
    await ApiService.post<BundleModel>(DatabaseEntities.BUNDLES, payload)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.CREATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.CREATED_FAILED);
      });
  };

  const invalidInput = (): boolean => {
    if (!name || name == "") return true;

    // Confirm if all thw items has a productId and a quantity
    if (items.length > 0)
      return (
        items.some((x) => x.productId == "") ||
        items.some((x) => x.quantity == 0)
      );

    return false;
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Typography className={classes.subTitle} onClick={handleOpen}>
          Bundle
        </Typography>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        {toggleSpinner ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <div className={classes.headerDiv}>
              {edit ? (
                <Typography className={classes.title}>
                  <DeleteModal item={bundle} handleDelete={handleDelete} />
                </Typography>
              ) : (
                <Typography className={classes.title}>Create bundle</Typography>
              )}
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
            {edit && <hr />}
            <form className={classes.form}>
              <Typography className={classes.label}>
                Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <input
                className={classes.input}
                value={name}
                onChange={handleName}
                placeholder="Add text..."
              />
              <Typography className={classes.label}>
                Description <span style={{ color: "red" }}>*</span>
              </Typography>
              <textarea
                className={classes.input}
                value={description}
                onChange={handleDescription}
                placeholder="Add a description.."
              />
              {!edit && (
                <>
                  <div className={classes.inputContainer}>
                    <Typography className={classes.label}>Image</Typography>
                  </div>
                  <div className={classes.uploadInput}>
                    <input
                      type="file"
                      id="add-single-img"
                      accept="image/*"
                      className="d-none"
                      onChange={handleFileChange}
                    />
                    <label
                      id="image-label"
                      htmlFor="add-single-img"
                      className={classes.label}
                    >
                      <img
                        src={UploadIcon}
                        className={classes.image}
                        alt="upload icon"
                      />
                      <label>Drop files to upload or browse</label>
                      <Typography>{fileName}</Typography>
                    </label>
                  </div>
                </>
              )}
              <div className="table-container">
                <table className="styled-table">
                  <thead className="">
                    <td>Product/Service</td>
                    <td>Quantity</td>
                    <td>Action</td>
                  </thead>
                  <tbody>
                    <tr className="add-item-row">
                      <td colSpan={3} className="add-item">
                        <span onClick={handleAddItem}>+ Add Item</span>
                      </td>
                    </tr>
                    {items.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          <select
                            className={classes.input}
                            onChange={(e) => handleProduct(e, index)}
                            value={item.productId}
                          >
                            <option value="">Select product/service</option>
                            {products.map((prod: ProductModel) => {
                              return (
                                <option key={prod.id} value={prod.id}>
                                  {prod.name}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            placeholder="01"
                            min={1}
                            max={99}
                            value={items[index].quantity}
                            onChange={(e) => handleQuantity(e, index)}
                          />
                        </td>
                        <td>
                          <Button
                            className="delete-btn"
                            onClick={() => handleDeleteItem(index)}
                          >
                            🗑️
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </form>
            <div className={classes.buttonDiv}>
              <Btn
                className={classes.button}
                onClick={submit}
                disabled={invalidInput()}
              >
                Save
              </Btn>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default BundleModal;
