import TagManager from 'react-gtm-module';

export function gtag_report_conversion(url: any) {
  const gtag = window.gtag;

  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  gtag("event", "conversion", {
    send_to: "AW-16663821488/z9GKCJ6LvMsZELD59ok-",
    value: 0.0,
    currency: "USD",
    event_callback: callback,
  });
  return false;
}

export function gtag_report_conversion_external() {
  TagManager.initialize({ gtmId: 'GTM-PX2ZX6XQ' });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    component: 'AdsComponent'
  });
}