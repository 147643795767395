import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import EstimateModel from "../../model/estimate";
import useStyles from "./styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

interface Props {
  estimate: EstimateModel;
  handleUpdate: any;
  handleDelete: any;
}

const EstimateDetailModal = ({ estimate, handleUpdate, handleDelete }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          <Box sx={style}>
            <div className={classes.headerDiv}>
              <Typography className={classes.title}></Typography>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default EstimateDetailModal;
