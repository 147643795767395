import { makeStyles } from "@material-ui/core/styles";
import {
  Black,
  DarkGrey,
  LightPrimaryColor,
  PrimaryColor,
  White,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  sidebar: {
    textAlign: "left",
    border: "1px solid darkGrey",
  },
  topDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  topButton: {
    marginLeft: "80%",
  },
  fullLogo: {
    width: "150px",
    marginTop: "50px",
    marginLeft: "20px",
  },
  logo: {
    width: "60px",
    marginTop: "50px",
    marginLeft: "0px",
  },
  icon: {
    margin: "2%",
    "& path": {
      fill: White,
    },
  },
  menu: {
    marginTop: "20%",
    marginRight: "2%",
  },
  selectedMenu: {
    background: PrimaryColor,
    color: White,
    borderRadius: "15px",
    padding: "5px 5px 5px 15px",
    "&:hover": {
      color: Black,
      background: LightPrimaryColor,
    },
  },
  selectedText: {
    color: White,
    "&:hover": {
      color: DarkGrey,
    },
  },
  text: {
    color: DarkGrey,
    fontWeight: 700,
  },
}));
