import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Tabs, Tab } from "@mui/material";
import {
  Button as Btn,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Spinner } from "../../components";
import DeleteModal from "../modal/DeleteModal";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../assets/admin/edit-icon.svg";
import EstimateModel from "../../model/estimate";
import useStyles from "./styles";
import { PrimaryColor } from "../../shared/styles/colors";
import MainLogo from "../../assets/main.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80rem",
  bgcolor: "#F9F9F9",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const EstimateModal = ({ estimate, handleUpdate, handleDelete }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [value, setValue] = useState(0); // Active tab

  const edit = estimate != null;
  const id = edit ? estimate.id : 0;
  const [code, setCode] = useState(edit ? estimate.code : generateKey());

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Estimate
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        {toggleSpinner ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <Card style={{ padding: "10px" }}>
              <div className={classes.headerDiv}>
                {edit ? (
                  <Typography className={classes.title}>
                    <DeleteModal item={estimate} handleDelete={handleDelete} />
                  </Typography>
                ) : (
                  <Typography variant="subtitle1">Estimate</Typography>
                )}
                <Button onClick={handleClose} sx={{ color: "black" }}>
                  <CloseIcon />
                </Button>
              </div>
              <div>
                <hr />
                <Box
                  sx={{ width: "20%", borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    variant="standard"
                    textColor="inherit"
                    indicatorColor="primary"
                    TabIndicatorProps={{
                      sx: { height: 2, bgcolor: PrimaryColor }, // Customize the underline
                    }}
                  >
                    <Tab
                      label="Edit"
                      sx={{
                        opacity: value === 0 ? 1 : 0.5,
                        textTransform: "capitalize",
                      }}
                    />
                    <Tab
                      label="PDF View"
                      sx={{
                        opacity: value === 1 ? 1 : 0.5,
                        textTransform: "capitalize",
                      }}
                    />
                  </Tabs>
                </Box>
              </div>
            </Card>
            <form className={classes.form}>
              <Card>
                <CardContent>
                  <div className={classes.headerDiv}>
                    <div>
                      <Typography className={classes.title}>
                        Estimate
                      </Typography>
                      <Typography className="">{`#${code}`}</Typography>
                    </div>
                    <img src={MainLogo} alt="logo" width={140} />
                  </div>
                </CardContent>
              </Card>
            </form>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default EstimateModal;

function generateKey(): number {
  // Generate a random number between 100000 and 999999
  const randomNumber = Math.floor(100000 + Math.random() * 900000);
  // Prefix with #
  return randomNumber;
}
