import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Budget, Spinner } from "../../../index";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../../../../firebase";
import { Carousel } from "react-responsive-carousel";
import { Card, CardContent, Typography } from "@material-ui/core";
import {
  Kitchen,
  Bathroom,
  Painting,
  Window,
  Flooring,
  Basement,
  Deck,
  Framing,
  Carpentry,
} from "./../../index";
import useStyles from "./styles";
import Pathnames from "../../../../model/enum/Pathnames";
import { Skeleton } from "@mui/material";
import FullscreenImage from "../../../../shared/components/images/FullscreenImage";

const Category = () => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([]);
  const [toggleSpinner, setToggleSpinner] = useState(true);
  const [imgSrc, setImgSrc] = useState("");
  const location = useLocation();
  const pathname = location.pathname;
  var category = pathname.split("/")[2];
  if (category === "painting_and_staining") category = "Painting";
  if (category === "door_and_windows") category = "Door & Windows";
  if (category === "porch_and_decks") category = "Porch & Decks";
  if (category === "framing_and_sheetrock") category = "Framing";
  const [fullScreen, setFullScreen] = useState(false);
  const [link, setLink] = useState("");

  useEffect(() => {
    document.title = "Service: " + category + " - Setup Remodeling";
    mapImgSource();
  });

  useEffect(() => {
    const fetchImages = async () => {
      var listRef = ref(storage, category);
      await listAll(listRef)
        .then((res) => {
          res.items.forEach((itemRef) => {
            getDownloadURL(itemRef)
              .then((x: string) => {
                setData((data) => [...data, x]);
              })
              .then(() => {
                setTimeout(() => {
                  setToggleSpinner(false);
                }, 2000);
              });
          });
        })
        .catch((error: any) => {
          console.log(error.message);
          setToggleSpinner(false);
        });
      setToggleSpinner(false);
    };

    if (data.length <= 0) fetchImages();
  }, [data]);

  const handleLink = (url: string) => {
    setLink(url);
  };

  const toggleFullScreen = (e: any) => {
    handleLink(data[e]);
    setFullScreen((fullScreen) => !fullScreen);
  };

  const mapImgSource = () => {
    switch (pathname) {
      case Pathnames.KITCHEN_SERVICE:
        setImgSrc(() => Kitchen);
        break;
      case Pathnames.BATHROOM_SERVICE:
        setImgSrc(() => Bathroom);
        break;
      case Pathnames.PAINTING_AND_STAINING_SERVICE:
        setImgSrc(() => Painting);
        break;
      case Pathnames.DOOR_AND_WINDOWS_SERVICE:
        setImgSrc(() => Window);
        break;
      case Pathnames.FRAMING_AND_SHEETROCK_SERVICE:
        setImgSrc(() => Framing);
        break;
      case Pathnames.FLOORING_SERVICE:
        setImgSrc(() => Flooring);
        break;
      case Pathnames.BASEMENT_SERVICE:
        setImgSrc(() => Basement);
        break;
      case Pathnames.PORCH_AND_DECK_SERVICE:
        setImgSrc(() => Deck);
        break;
      case Pathnames.CARPENTRY_SERVICE:
        setImgSrc(() => Carpentry);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.container}>
      {toggleSpinner ? (
        <Spinner />
      ) : (
        <Card className={classes.categoryCard} raised={true}>
          <CardContent>
            <div className="d-flex column align-items-center">
              <img
                className={classes.img}
                src={imgSrc}
                alt={category}
                loading="lazy"
              ></img>
              <Typography className={classes.title}>{category}</Typography>
            </div>
            <Typography className={classes.description}>
              SETUP REMODELING delivers unparalleled craftsmanship and attention
              to detail in every project. Trust us to enhance every aspect of
              your home with our wide range of expert services.
            </Typography>
            {data ? (
              <Carousel
                className={classes.carousel}
                autoPlay={false}
                infiniteLoop={true}
                interval={6000}
                showArrows={true}
                showThumbs={false}
                showIndicators={false}
                onClickItem={(e) => toggleFullScreen(e)}
              >
                {data.map((categ: any) => {
                  return (
                    <div key={categ}>
                      <img
                        src={categ}
                        alt={category}
                        className={classes.content}
                      />
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              <Skeleton variant="rounded" height={535} width={"100%"} />
            )}
          </CardContent>
        </Card>
      )}
      {(category === "Kitchen" || category === "Bathroom") && (
        <Budget category={category} />
      )}
      {fullScreen && (
        <FullscreenImage imageUrl={link} toggleFullScreen={toggleFullScreen} />
      )}
    </div>
  );
};

export default Category;
