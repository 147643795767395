import React from "react";
import { NumberFormatter } from "react-number-formatter";
import useStyles from "../styles";
import { Typography } from "@material-ui/core";

const Step3 = ({
  handleAddress,
  address,
  handleFirstName,
  firstName,
  handleLastName,
  lastName,
  handleEmail,
  email,
  handlePhone,
  phone,
}: any) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.subtitleDiv}>
        <Typography className={classes.subtitle}>
          Fill in the form with your personal information
        </Typography>
      </div>
      <div className={classes.inputDiv}>
        <input
          className={classes.input}
          placeholder="Your first name..."
          onChange={handleFirstName}
          required
          value={firstName}
        />
        <input
          className={classes.input}
          placeholder="Your last name..."
          onChange={handleLastName}
          required
          value={lastName}
        />
      </div>
      <div className={classes.inputDiv}>
        <input
          className={classes.input}
          type="text"
          placeholder="Street Address..."
          onChange={handleAddress}
          required
          value={address}
        />
      </div>
      <div className={classes.inputDiv}>
        <input
          className={classes.input}
          placeholder="Your email..."
          onChange={handleEmail}
          required
          value={email}
        />
        <div className={classes.input}>
          <NumberFormatter
            defaultCountry="USA"
            placeholder="Your phone..."
            value={phone}
            getValue={(n: string) => handlePhone(n)}
          />
        </div>
      </div>
    </>
  );
};

export default Step3;
