import React from "react";
import useStyles from "../styles";
import { Typography } from "@material-ui/core";
import Service from "../../../model/service";
import ServiceType from "../../../model/serviceType";

const Step2 = ({
  handleServiceType,
  serviceTypeId,
  handleService,
  serviceId,
  handleDescription,
  description,
  services,
  serviceTypes,
}: any) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.subtitleDiv}>
        <Typography className={classes.subtitle}>
          Please, provide the information about your project!
        </Typography>
      </div>
      <div>
        <div className={classes.inputDiv}>
          <select
            className={classes.input}
            onChange={handleServiceType}
            value={serviceTypeId}
          >
            <option value="0" disabled>
              Select a project...
            </option>
            {serviceTypes.map((type: ServiceType) => {
              return (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.inputDiv}>
          <select
            className={classes.input}
            onChange={handleService}
            value={serviceId}
          >
            <option value="0" disabled>
              Select a service...
            </option>
            {services.map((service: Service) => {
              return (
                <option key={service.id} value={service.id}>
                  {service.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.inputDiv}>
          <textarea
            className={classes.input}
            name="description"
            placeholder="Project Description..."
            onChange={handleDescription}
            defaultValue={description}
          />
        </div>
      </div>
    </>
  );
};

export default Step2;
