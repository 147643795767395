import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { PrimaryColor } from "../../shared/styles/colors";
import { Card, CardContent, Typography } from "@material-ui/core";
import useStyles from "../styles/cardStyles";
import Products from "./product/Products";
import Categories from "./category/Categories";
import Vendors from "./vendor/Vendors";
import ItemModal from "./ItemModal";
import Bundles from "./bundle/Bundles";
import { useLocation, useNavigate } from "react-router-dom";
import Pathnames from "../../model/enum/Pathnames";

const Overview = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  let pathname = location.pathname;

  const mapValueFromUrl = (): number => {
    switch (pathname.split("/")[4]) {
      case "bundles":
        return 1;
      case "categories":
        return 2;
      case "vendors":
        return 3;
    }

    return 0;
  };

  const [value, setValue] = useState(mapValueFromUrl()); // Active tab

  const Type = () => {
    switch (value) {
      case 0:
        return <Products />;
      case 1:
        return <Bundles />;
      case 2:
        return <Categories />;
      case 3:
        return <Vendors />;
    }

    return <></>;
  };

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Card raised className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>Overview</Typography>
          <ItemModal />
        </div>
        <CardContent>
          <Box sx={{ width: "50%", borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              variant="fullWidth"
              textColor="inherit"
              indicatorColor="primary"
              TabIndicatorProps={{
                sx: { height: 2, bgcolor: PrimaryColor }, // Customize the underline
              }}
            >
              <Tab
                label="Products"
                sx={{
                  opacity: value === 0 ? 1 : 0.5,
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(Pathnames.PRODUCTS_SERVICES_LIST)}
              />
              <Tab
                label="Bundles"
                sx={{
                  opacity: value === 1 ? 1 : 0.5,
                  textTransform: "capitalize",
                }}
                onClick={() =>
                  navigate(Pathnames.PRODUCTS_SERVICES_BUNDLE_LIST)
                }
              />
              <Tab
                label="Categories"
                sx={{
                  opacity: value === 2 ? 1 : 0.5,
                  textTransform: "capitalize",
                }}
                onClick={() =>
                  navigate(Pathnames.PRODUCTS_SERVICES_CATEGORY_LIST)
                }
              />
              <Tab
                label="Vendors"
                sx={{
                  opacity: value === 3 ? 1 : 0.5,
                  textTransform: "capitalize",
                }}
                onClick={() =>
                  navigate(Pathnames.PRODUCTS_SERVICES_VENDOR_LIST)
                }
              />
            </Tabs>
          </Box>
          <Type />
        </CardContent>
      </Card>
    </>
  );
};

export default Overview;
