import { makeStyles } from "@material-ui/core/styles";
import {
  Black,
  DarkGrey,
  InputColor,
  LightGrey,
  LightPrimaryColor,
  White,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 640px)": {
      alignItems: "center",
    },
  },
  headerSubDiv: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    color: Black,
    fontSize: "1.3rem",
    fontWeight: "700",
  },
  form: {
    marginTop: "5%",
  },
  label: {
    fontWeight: "600",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    width: "99% !important",
    alignItems: "center",
    padding: 0,
    "@media (max-width: 640px)": {
      flexDirection: "column",
      width: "100% !important",
    },
  },
  groupedItems: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
  },
  input: {
    width: "95% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
      color: InputColor,
    },
  },
  inputBordered: {
    width: "95% !important",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  textareaBordered: {
    width: "95% !important",
    height: "5rem",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
      height: "10rem",
    },
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    width: "80% !important",
    marginLeft: "10%",
    marginTop: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  button: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
    margin: "0.5rem",
    width: "12rem",
    color: DarkGrey,
    background: White,
    border: "1px solid grey",
    borderRadius: "10px",
    textTransform: "none",
    alignItems: "center",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
  },
}));
