import React, { ChangeEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  PrimaryColor,
  TableBodyGrey,
  TableHeaderGrey,
} from "../../shared/styles/colors";
import FaqModel from "../../model/faq";
import FirebaseService from "../../service/FirebaseService";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FaqModal from "./FaqModal";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import AlertService from "../../service/AlertService";
import ToastMessages from "../../model/enum/ToastMessages";
import { Spinner } from "../../components";
import { ToastContainer } from "react-toastify";
import FaqDetailModal from "./FaqDetailModal";
import Status from "../../model/enum/StatusClass";
import CachedIcon from "@mui/icons-material/Cached";
import useStyles from "../styles/cardStyles";
import ApiService from "../../service/ApiService";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Faqs = () => {
  const classes = useStyles();
  const [faqs, setFaqs] = useState<FaqModel[]>([]);
  const [items, setItems] = useState<FaqModel[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  //Get all appointments from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<FaqModel[]>(DatabaseEntities.FAQS).then(
        (data: FaqModel[]) => {
          setFaqs(data);

          for (let i = 0; i < rowsPerPage && i < data.length; i++) {
            items.push(data[i]);
          }

          setToggleSpinner(false);
        }
      );
    };

    if (faqs.length <= 0) fetchData();
  });

  const handleCreate = async (payload: any, notify: boolean = true) => {
    await ApiService.post<FaqModel>(DatabaseEntities.FAQS, payload)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.CREATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.CREATED_FAILED);
      });
  };

  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<FaqModel>(
      `${DatabaseEntities.FAQS}/${payload.id}`,
      payload
    )
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  const handleDelete = async (payload: any, notify: boolean = true) => {
    await ApiService.delete<any>(`${DatabaseEntities.FAQS}/${payload.id}`)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.DELETED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.DELETE_FAILED);
      });
  };

  const resetList = (): void => {
    setFaqs([]);
    setItems([]);
    setPage(0);
  };

  const handlePageChange = (
    event: ChangeEvent<unknown> | null,
    pg: number,
    itemsPerPage: number = rowsPerPage
  ) => {
    setToggleSpinner(true);
    setPage(pg);
    let MaxElem = pg * itemsPerPage;

    items.forEach((_itm: FaqModel, index) => {
      items.splice(index);
    });

    for (let i = MaxElem; i < MaxElem + itemsPerPage; i++) {
      if (i < faqs.length) items.push(faqs[i]);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setToggleSpinner(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let pg = parseInt(event.target.value);
    setRowsPerPage((_rowsPerPage) => pg);
    setPage((_page) => 0);
    handlePageChange(null, 0, pg);
  };

  return (
    <>
      <Card raised className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>FAQ's</Typography>
          <div className={classes.headerDiv}>
            <FaqModal handleCreate={handleCreate} />
            <Button onClick={resetList}>
              <CachedIcon />
            </Button>
          </div>
        </div>
        <CardContent>
          <Paper>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="customized table"
                padding="normal"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell align="right">Question</StyledTableCell>
                    <StyledTableCell align="right">Answer</StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                    <StyledTableCell align="center">
                      <MoreVertIcon />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {toggleSpinner ? (
                  <Spinner />
                ) : (
                  <TableBody>
                    {items.length > 0 ? (
                      items.map((row: FaqModel) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell align="right">
                            {row.id}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.question}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.answer}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Typography
                              style={Status.mapStatusColor(
                                row.available ? Status.ACTIVE : Status.INACTIVE
                              )}
                            >
                              {" "}
                              <div
                                style={Status.mapStatusRoundedColor(
                                  row.available
                                    ? Status.ACTIVE
                                    : Status.INACTIVE
                                )}
                              ></div>
                              {row.available
                                ? Status.AVAILABLE
                                : Status.UNAVAILABLE}{" "}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FaqDetailModal
                              faq={row}
                              handleUpdate={handleUpdate}
                              handleDelete={handleDelete}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableCell align="right">No items</StyledTableCell>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            component="div"
            count={faqs.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default Faqs;
