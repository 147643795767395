import { makeStyles } from "@material-ui/core/styles";
import { Black } from "../../shared/styles/colors";

export default makeStyles(() => ({
  card: {
    maxWidth: "100%",
    borderRadius: "8px",
    padding: "1rem",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    "@media (max-width: 640px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  headerDiv: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
  },
  groupedItems: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "700",
    wordWrap: "break-word",
    color: Black,
  },
  limitedText: {
    display: "block",
    width: "180px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  clockStyle: {
    border: "none",
    "& div, input": {
      border: "none",
    },
  },
}));
